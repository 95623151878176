//
// symbol
//

$default-size: 35px;

$symbol-sizes: (
    20: 20px,
    25: 25px,
    30: 30px,
    35: 35px,
    40: 40px,
    45: 45px,
    50: 50px,
    55: 55px,
    60: 60px,
    65: 65px,
    70: 70px,
    75: 75px,
    80: 80px,
    85: 85px,
    90: 90px,
    95: 95px,
    100:100px,
    120:120px,
    130:130px,
    140:140px,
    150:150px
);

.bg-white-o-30 {
}

// Base
.symbol {
    display: inline-block;;
    flex-shrink: 0;
    position: relative;
    @include border-radius($border-radius);

    // Label
    .symbol-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        line-height: 0;
        color: $dark-75;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: rgba(255,255,255,0.3);

        @include border-radius($border-radius);
    }

    // Badge
    .symbol-badge {
        position: absolute;
        border: 2px solid $white;
        border-radius: 100%;
        top: 0;
        right: 0;
    }

    // Image
    > img {
        display: flex;
        @include border-radius($border-radius);
    }

    // Circle style
    &.symbol-circle {
        @include border-radius(50%);

        > img {
            @include border-radius(50%);
        }

        .symbol-label {
            @include border-radius(50%);
        }
    }

    // Theme colors
    @each $color, $value in $theme-colors {
        // Default style
        &.symbol-#{$color} {
            .symbol-label {
                background-color: $value;
                color: theme-inverse-color($color);
            }
        }

        // Light style
        &.symbol-light-#{$color} {
            .symbol-label {
                background-color: theme-light-color($color);
                color: $value;
            }
        }
    }

    // Responsize sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $name, $value in $symbol-sizes {
                @if ($name == 'default') {
                    // Sizing
                    > img {
                        width: 100%;
                        max-width: $default-size;
                        height: $default-size;
                    }

                    .symbol-label {
                        width: $default-size;
                        height: $default-size;
                    }

                } @else {
                    &.symbol#{$infix}-#{$name} {
                        > img {
                            width: 100%;
                            max-width: $value;
                            height: $value;
                        }

                        .symbol-label {
                            width: $value;
                            height: $value;
                        }

                        $badge-size:  12px;

                        .symbol-badge {
                            width: $badge-size;
                            height: $badge-size;
                            top: -($badge-size/2);
                            right: -($badge-size/2);

                            &.symbol-badge-bottom {
                                top: auto;
                                bottom: -($badge-size/2);
                            }
                        }

                        &.symbol-circle {
                            $distance: ($value / 2) * (1.41 - 1) - $badge-size;

                            .symbol-badge {
                                top: $distance;
                                right: $distance;

                                &.symbol-badge-bottom {
                                    top: auto;
                                    bottom: $distance
                                }
                            }
                        }

                        &.symbol-fixed {
                            .symbol-label {
                                width: $value;
                                height: $value;
                            }

                            > img {
                                width: $value;
                                height: $value;
                                max-width: auto;
                            }
                        }

                        // Ratios
                        &.symbol-2by3 {
                            .symbol-label {
                                height: $value;
                                width:  $value * 3/2;
                            }

                            > img {
                                height: $value;
                                width:  $value * 3/2;
                                max-width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

}

// symbol Group
.symbol-group {
    display: flex;
    align-items: center;

    .symbol {
        position: relative;
        z-index: 0;
        margin-left: -10px;
        border: 2px solid $white;
        transition: all 0.3s ease;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            transition: all 0.3s ease;
            z-index: 1;
        }
    }

    &.symbol-hover {
        .symbol {
            cursor: pointer;
        }
    }
}

// symbol List
.symbol-list {
    display: flex;
    align-items: center;

    .symbol {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

