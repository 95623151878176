//
// Radio
//

// Base
.custom-control {
  &.custom-control-inline {
    .custom-control-radio {
      z-index: 10;
      opacity: 1;
      position: absolute;
      left: 0;
      width: 1rem;
      height: 1.25rem;
    }
  }
}
