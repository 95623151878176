@use "sass:map";

$svg-icon-sizes: (
    sm: 1.25rem,
    md: 1.5rem,
    lg: 1.75rem,
    xl: 2rem,
    xxl: 2.25rem,

    1x: 1rem,
    2x: 2rem,
    3x: 3rem,
    4x: 4rem,
    5x: 5rem,
    6x: 6rem,
    7x: 7rem,
    8x: 8rem,
    9x: 9rem,
	10x: 10rem,

	full: 100%
);

@mixin svg-icon-color($color) {
    svg {
        g {
            [fill] {
                transition: fill 0.3s ease;
                fill: $color;
            }
        }

        &:hover {
            g {
                [fill] {
                    transition: fill 0.3s ease;
                }
            }
        }
    }
}

@mixin svg-icon-transition() {
    svg {
        g {
            [fill] {
                transition: fill 0.3s ease;
            }
        }
    }
}

@mixin svg-icon-size($size) {
    svg {
        height: $size;
        width: $size;
    }
}


.svg-icon {
    display: inline-block;
    position: relative;
    @include svg-icon-size(map.get($svg-icon-sizes, "md"));

    svg {
        pointer-events: none;
    }

    .super-icon {
      display: inline-block;
      position: absolute;
      top: -0.2*map.get($svg-icon-sizes, "md");
      left: 55%;
      svg {
        height: 0.6*map.get($svg-icon-sizes, "md");
        width: 0.6*map.get($svg-icon-sizes, "md");
      }
    }

    // Theme colors
    @each $name, $color in $theme-text-colors  {
        &.svg-icon-#{$name} {
            @include svg-icon-color($color);
        }
    }

    @each $name, $value in $svg-icon-sizes  {
        &.svg-icon-#{$name} {
            @include svg-icon-size($value);

            .super-icon {
              display: inline-block;
              position: absolute;
              top: -0.2*$value;
              left: 55%;
              svg {
                height: 0.6*$value;
                width: 0.6*$value;
              }
            }

            .sub-icon {
              display: inline-block;
              position: absolute;
              bottom: -0.2*$value;
              left: 55%;
              svg {
                height: 0.6*$value;
                width: 0.6*$value;
              }
            }
        }
    }

    // Responsive icon sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            // Sizes
            @each $name, $value in $svg-icon-sizes  {
                &.svg-icon#{$infix}-#{$name} {
                    @include svg-icon-size($value);
                }
            }
        }
    }

}

.svg-bg-transparent {
  fill: $primary;
  opacity: 0.04;
}
