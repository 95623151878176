//
// Divider
//

.divider {
    height: 0;

    // Dashed style
    &.divider-dashed {
        border-bottom: 1px dashed $border-color;
    }

    &.divider-solid {
        border-bottom: 1px solid $border-color;
    }

    &.divider-border-2 {
        border-bottom-width: 2px;
    }

    &.divider-border-3 {
        border-bottom-width: 3px;
    }

    &.divider-border-4 {
        border-bottom-width: 4px;
    }

    // Theme colors
    @each $name, $color in $theme-colors {
        &.divider-#{$name} {
            border-bottom-color: $color;
        }
    }
}
