.max-vh-25 {
  max-height: 25vh;
}

.max-vh-50 {
  max-height: 50vh;
}

.max-vh-75 {
  max-height: 75vh;
}

.min-vh-25 {
  min-height: 25vh;
}

.vh-75 {
  height: 75vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-75 {
  min-height: 75vh;
}

.min-vw-50 {
  min-width: 50vw;
}

.min-vw-25 {
  min-width: 25vw;
}

.max-w-100p {
  max-width: 100px;
}

.w-30p {
  width: 30px;
}

.w-80p {
  width: 80px;
}

.w-100p {
  width: 100px;
}

.w-150p {
  width: 150px;
}

.w-300p {
  width: 300px;
}
