.treeview-node,
.treeview-children,
.treeview-container-ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.treeview-node {
  white-space: nowrap;
}

.treeview-row.no-icon {
  padding-left: 16px;
}

.treeview.reordering {
  opacity: 0.5;
}

.treeview-move {
  cursor: s-resize;
}

.treeview-anchor {
  display: inline-block;
  color: black;
  white-space: nowrap;
  padding: 0 4px 0 1px;
  margin: 0;
  vertical-align: top;
  text-overflow: ellipsis;
}

.treeview-anchor:focus {
  outline: 0;
}
.treeview-anchor,
.treeview-anchor:link,
.treeview-anchor:visited,
.treeview-anchor:hover,
.treeview-anchor:active {
  text-decoration: none;
  color: inherit;
}

.treeview-row {
  .treeview-actions {
    visibility: hidden;
  }
}

.treeview-row:hover {
  .treeview-node-header {
    background-color: $hover-bg;
  }
  .treeview-actions {
    visibility: visible;
  }
}

.treeview .treeview-open > .treeview-children {
  min-height: 10px;
}

.treeview-icon {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}
.treeview-icon:empty {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}
.treeview-ocl {
  cursor: pointer;
}
.treeview-leaf > .treeview-row > .treeview-ocl {
  cursor: default;
}
.treeview .treeview-open > .treeview-children {
  display: block;
}
.treeview .treeview-closed > .treeview-children {
  display: none;
}
.treeview-anchor > .treeview-themeicon {
  margin-right: 2px;
}
.treeview-no-icons .treeview-themeicon,
.treeview-anchor > .treeview-themeicon-hidden {
  display: none;
}
.treeview-hidden,
.treeview-node.treeview-hidden {
  display: none;
}
.treeview-rtl .treeview-anchor {
  padding: 0 1px 0 4px;
}
.treeview-rtl .treeview-anchor > .treeview-themeicon {
  margin-left: 2px;
  margin-right: 0;
}
.treeview-rtl .treeview-node {
  margin-left: 0;
}
.treeview-rtl .treeview-container-ul > .treeview-node {
  margin-right: 0;
}
.treeview-wholerow-ul {
  position: relative;
  display: inline-block;
  min-width: 100%;
}
.treeview-wholerow-ul .treeview-leaf > .treeview-row > .treeview-ocl {
  cursor: pointer;
}
.treeview-wholerow-ul .treeview-anchor,
.treeview-wholerow-ul .treeview-icon {
  position: relative;
}
.treeview-wholerow-ul .treeview-wholerow {
  width: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.treeview-contextmenu .treeview-anchor {
  -webkit-user-select: none;
  /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none;
  /* disable the IOS popup when long-press on a link */
}
.vakata-context {
  display: none;
}
.vakata-context,
.vakata-context ul {
  margin: 0;
  padding: 2px;
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #979797;
  box-shadow: 2px 2px 2px #999999;
}
.vakata-context ul {
  list-style: none;
  left: 100%;
  margin-top: -2.7em;
  margin-left: -4px;
}
.vakata-context .vakata-context-right ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}
.vakata-context li {
  list-style: none;
}
.vakata-context li > a {
  display: block;
  padding: 0 2em 0 2em;
  text-decoration: none;
  width: auto;
  color: black;
  white-space: nowrap;
  line-height: 2.4em;
  text-shadow: 1px 1px 0 white;
  border-radius: 1px;
}
.vakata-context li > a:hover {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}
.vakata-context li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
  background-position: right center;
  background-repeat: no-repeat;
}
.vakata-context li > a:focus {
  outline: 0;
}
.vakata-context .vakata-context-hover > a {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}
.vakata-context .vakata-context-separator > a,
.vakata-context .vakata-context-separator > a:hover {
  background: white;
  border: 0;
  border-top: 1px solid #e2e3e3;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  padding: 0;
  margin: 0 0 0 2.4em;
  border-left: 1px solid #e0e0e0;
  text-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  border-radius: 0;
}
.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0;
}
.vakata-context .vakata-contextmenu-disabled > a > i {
  filter: grayscale(100%);
}
.vakata-context li > a > i {
  text-decoration: none;
  display: inline-block;
  width: 2.4em;
  height: 2.4em;
  background: transparent;
  margin: 0 0 0 -2em;
  vertical-align: top;
  text-align: center;
  line-height: 2.4em;
}
.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}
.vakata-context li > a .vakata-contextmenu-sep {
  display: inline-block;
  width: 1px;
  height: 2.4em;
  background: white;
  margin: 0 0.5em 0 0;
  border-left: 1px solid #e2e3e3;
}
.vakata-context .vakata-contextmenu-shortcut {
  font-size: 0.8em;
  color: silver;
  opacity: 0.5;
  display: none;
}
.vakata-context-rtl ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}
.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
  background-position: left center;
  background-repeat: no-repeat;
}
.vakata-context-rtl .vakata-context-separator > a {
  margin: 0 2.4em 0 0;
  border-left: 0;
  border-right: 1px solid #e2e3e3;
}
.vakata-context-rtl .vakata-context-left ul {
  right: auto;
  left: 100%;
  margin-left: -4px;
  margin-right: auto;
}
.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}
.vakata-context-rtl li > a .vakata-contextmenu-sep {
  margin: 0 0 0 0.5em;
  border-left-color: white;
  background: #e2e3e3;
}
#treeview-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
#treeview-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px;
}
#treeview-dnd .treeview-icon,
#treeview-dnd .treeview-copy {
  display: inline-block;
  text-decoration: none;
  margin: 0 2px 0 0;
  padding: 0;
  width: 16px;
  height: 16px;
}
#treeview-dnd .treeview-ok {
  background: green;
}
#treeview-dnd .treeview-er {
  background: red;
}
#treeview-dnd .treeview-copy {
  margin: 0 2px 0 2px;
}
.treeview-default .treeview-node,
.treeview-default .treeview-icon {
  background-repeat: no-repeat;
  background-color: transparent;
}
.treeview-default .treeview-anchor,
.treeview-default .treeview-animated,
.treeview-default .treeview-wholerow {
  transition: background-color 0.15s, box-shadow 0.15s;
}
.treeview-default .treeview-hovered {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #cccccc;
}
.treeview-default .treeview-context {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #cccccc;
}
.treeview-default .treeview-clicked {
  background: #beebff;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999999;
}
.treeview-default .treeview-no-icons .treeview-anchor > .treeview-themeicon {
  display: none;
}
.treeview-default .treeview-disabled {
  background: transparent;
  color: #666666;
}
.treeview-default .treeview-disabled.treeview-hovered {
  background: transparent;
  box-shadow: none;
}
.treeview-default .treeview-disabled.treeview-clicked {
  background: #efefef;
}
.treeview-default .treeview-disabled > .treeview-icon {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'treeview-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#treeview-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
.treeview-default .treeview-search {
  font-style: italic;
  color: #8b0000;
  font-weight: bold;
}
.treeview-default .treeview-no-checkboxes .treeview-checkbox {
  display: none !important;
}
.treeview-default.treeview-checkbox-no-clicked .treeview-clicked {
  background: transparent;
  box-shadow: none;
}
.treeview-default.treeview-checkbox-no-clicked .treeview-clicked.treeview-hovered {
  background: #e7f4f9;
}
.treeview-default.treeview-checkbox-no-clicked > .treeview-wholerow-ul .treeview-wholerow-clicked {
  background: transparent;
}
.treeview-default.treeview-checkbox-no-clicked > .treeview-wholerow-ul .treeview-wholerow-clicked.treeview-wholerow-hovered {
  background: #e7f4f9;
}
.treeview-default > .treeview-striped {
  min-width: 100%;
  display: inline-block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat;
}
.treeview-default > .treeview-wholerow-ul .treeview-hovered,
.treeview-default > .treeview-wholerow-ul .treeview-clicked {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.treeview-default .treeview-wholerow {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.treeview-default .treeview-wholerow-hovered {
  background: #e7f4f9;
}
.treeview-default .treeview-wholerow-clicked {
  background: #beebff;
  background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
  background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%);
}
.treeview-default .treeview-node {
  min-height: 24px;
  line-height: 24px;
  margin-left: 24px;
  min-width: 24px;
}
.treeview-default .treeview-anchor {
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.treeview-default .treeview-icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  min-width: 24px;
}
.treeview-default .treeview-icon:empty {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.treeview-default.treeview-rtl .treeview-node {
  margin-right: 24px;
}
.treeview-default .treeview-wholerow {
  height: 24px;
}
.treeview-default .treeview-node,
.treeview-default .treeview-icon {
  background-image: url("backgrounds/tree_32px.png");
}
.treeview-default .treeview-node {
  background-position: -292px -4px;
  background-repeat: repeat-y;
}

.treeview-default .treeview-node-header {
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeview-default .treeview-last {
  background: transparent;
}
.treeview-default .treeview-open > .treeview-ocl {
  background-position: -132px -4px;
}

.treeview-default .treeview-open > .treeview-row > .treeview-ocl {
  background-position: -132px -4px;
}

.treeview-default .treeview-closed > .treeview-row > .treeview-ocl {
  background-position: -100px -4px;
}
.treeview-default .treeview-leaf > .treeview-row > .treeview-ocl {
  background-position: -68px -4px;
}
.treeview-default .treeview-themeicon {
  background-position: -260px -4px;
}
.treeview-default > .treeview-no-dots .treeview-node,
.treeview-default > .treeview-no-dots .treeview-leaf > .treeview-row > .treeview-ocl {
  background: transparent;
}
.treeview-default > .treeview-no-dots .treeview-open > .treeview-ocl {
  background-position: -36px -4px;
}
.treeview-default > .treeview-no-dots .treeview-closed > .treeview-ocl {
  background-position: -4px -4px;
}
.treeview-default .treeview-disabled {
  background: transparent;
}
.treeview-default .treeview-disabled.treeview-hovered {
  background: transparent;
}
.treeview-default .treeview-disabled.treeview-clicked {
  background: #efefef;
}
.treeview-default .treeview-checkbox {
  background-position: -164px -4px;
}
.treeview-default .treeview-checkbox:hover {
  background-position: -164px -36px;
}
.treeview-default.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox,
.treeview-default .treeview-checked > .treeview-checkbox {
  background-position: -228px -4px;
}
.treeview-default.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox:hover,
.treeview-default .treeview-checked > .treeview-checkbox:hover {
  background-position: -228px -36px;
}
.treeview-default .treeview-anchor > .treeview-undetermined {
  background-position: -196px -4px;
}
.treeview-default .treeview-anchor > .treeview-undetermined:hover {
  background-position: -196px -36px;
}
.treeview-default .treeview-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'treeview-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#treeview-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
.treeview-default > .treeview-striped {
  background-size: auto 48px;
}
.treeview-default.treeview-rtl .treeview-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}
.treeview-default.treeview-rtl .treeview-last {
  background: transparent;
}
.treeview-default.treeview-rtl .treeview-open > .treeview-ocl {
  background-position: -132px -36px;
}
.treeview-default.treeview-rtl .treeview-closed > .treeview-ocl {
  background-position: -100px -36px;
}
.treeview-default.treeview-rtl .treeview-leaf > .treeview-row > .treeview-ocl {
  background-position: -68px -36px;
}
.treeview-default.treeview-rtl > .treeview-no-dots .treeview-node,
.treeview-default.treeview-rtl > .treeview-no-dots .treeview-leaf > .treeview-row > .treeview-ocl {
  background: transparent;
}
.treeview-default.treeview-rtl > .treeview-no-dots .treeview-open > .treeview-ocl {
  background-position: -36px -36px;
}
.treeview-default.treeview-rtl > .treeview-no-dots .treeview-closed > .treeview-ocl {
  background-position: -4px -36px;
}
.treeview-default .treeview-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}
.treeview-default > .treeview-container-ul .treeview-loading > .treeview-ocl {
  background: url("backgrounds/tree_throbber.gif") center center no-repeat;
}
.treeview-default .treeview-file {
  background: url("backgrounds/tree_32px.png") -100px -68px no-repeat;
}
.treeview-default .treeview-folder {
  background: url("backgrounds/tree_32px.png") -260px -4px no-repeat;
}
.treeview-default > .treeview-container-ul > .treeview-node {
  margin-left: 0;
  margin-right: 0;
}
#treeview-dnd.treeview-default {
  line-height: 24px;
  padding: 0 4px;
}
#treeview-dnd.treeview-default .treeview-ok,
#treeview-dnd.treeview-default .treeview-er {
  background-image: url("backgrounds/tree_32px.png");
  background-repeat: no-repeat;
  background-color: transparent;
}
#treeview-dnd.treeview-default i {
  background: transparent;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
#treeview-dnd.treeview-default .treeview-ok {
  background-position: -4px -68px;
}
#treeview-dnd.treeview-default .treeview-er {
  background-position: -36px -68px;
}
.treeview-default .treeview-ellipsis {
  overflow: hidden;
}
.treeview-default .treeview-ellipsis .treeview-anchor {
  width: calc(100% - 29px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.treeview-default.treeview-rtl .treeview-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
}
.treeview-default.treeview-rtl .treeview-last {
  background: transparent;
}
.treeview-default-small .treeview-node {
  min-height: 18px;
  line-height: 18px;
  margin-left: 18px;
  min-width: 18px;
}
.treeview-default-small .treeview-anchor {
  line-height: 18px;
  height: 18px;
}
.treeview-default-small .treeview-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.treeview-default-small .treeview-icon:empty {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.treeview-default-small.treeview-rtl .treeview-node {
  margin-right: 18px;
}
.treeview-default-small .treeview-wholerow {
  height: 18px;
}
.treeview-default-small .treeview-node,
.treeview-default-small .treeview-icon {
  background-image: url("backgrounds/tree_32px.png");
}
.treeview-default-small .treeview-node {
  background-position: -295px -7px;
  background-repeat: repeat-y;
}
.treeview-default-small .treeview-last {
  background: transparent;
}
.treeview-default-small .treeview-open > .treeview-ocl {
  background-position: -135px -7px;
}
.treeview-default-small .treeview-closed > .treeview-row > .treeview-ocl {
  background-position: -103px -7px;
}
.treeview-default-small .treeview-leaf > .treeview-row > .treeview-ocl {
  background-position: -71px -7px;
}
.treeview-default-small .treeview-themeicon {
  background-position: -263px -7px;
}
.treeview-default-small > .treeview-no-dots .treeview-node,
.treeview-default-small > .treeview-no-dots .treeview-leaf > .treeview-row > .treeview-ocl {
  background: transparent;
}
.treeview-default-small > .treeview-no-dots .treeview-open > .treeview-ocl {
  background-position: -39px -7px;
}
.treeview-default-small > .treeview-no-dots .treeview-closed > .treeview-ocl {
  background-position: -7px -7px;
}
.treeview-default-small .treeview-disabled {
  background: transparent;
}
.treeview-default-small .treeview-disabled.treeview-hovered {
  background: transparent;
}
.treeview-default-small .treeview-disabled.treeview-clicked {
  background: #efefef;
}
.treeview-default-small .treeview-checkbox {
  background-position: -167px -7px;
}
.treeview-default-small .treeview-checkbox:hover {
  background-position: -167px -39px;
}
.treeview-default-small.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox,
.treeview-default-small .treeview-checked > .treeview-checkbox {
  background-position: -231px -7px;
}
.treeview-default-small.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox:hover,
.treeview-default-small .treeview-checked > .treeview-checkbox:hover {
  background-position: -231px -39px;
}
.treeview-default-small .treeview-anchor > .treeview-undetermined {
  background-position: -199px -7px;
}
.treeview-default-small .treeview-anchor > .treeview-undetermined:hover {
  background-position: -199px -39px;
}
.treeview-default-small .treeview-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'treeview-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#treeview-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
.treeview-default-small > .treeview-striped {
  background-size: auto 36px;
}
.treeview-default-small.treeview-rtl .treeview-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}
.treeview-default-small.treeview-rtl .treeview-last {
  background: transparent;
}
.treeview-default-small.treeview-rtl .treeview-open > .treeview-ocl {
  background-position: -135px -39px;
}
.treeview-default-small.treeview-rtl .treeview-closed > .treeview-ocl {
  background-position: -103px -39px;
}
.treeview-default-small.treeview-rtl .treeview-leaf > .treeview-row > .treeview-ocl {
  background-position: -71px -39px;
}
.treeview-default-small.treeview-rtl > .treeview-no-dots .treeview-node,
.treeview-default-small.treeview-rtl > .treeview-no-dots .treeview-leaf > .treeview-row > .treeview-ocl {
  background: transparent;
}
.treeview-default-small.treeview-rtl > .treeview-no-dots .treeview-open > .treeview-ocl {
  background-position: -39px -39px;
}
.treeview-default-small.treeview-rtl > .treeview-no-dots .treeview-closed > .treeview-ocl {
  background-position: -7px -39px;
}
.treeview-default-small .treeview-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}
.treeview-default-small > .treeview-container-ul .treeview-loading > .treeview-ocl {
  background: url("backgrounds/tree_throbber.gif") center center no-repeat;
}
.treeview-default-small .treeview-file {
  background: url("backgrounds/tree_32px.png") -103px -71px no-repeat;
}
.treeview-default-small .treeview-folder {
  background: url("backgrounds/tree_32px.png") -263px -7px no-repeat;
}
.treeview-default-small > .treeview-container-ul > .treeview-node {
  margin-left: 0;
  margin-right: 0;
}
#treeview-dnd.treeview-default-small {
  line-height: 18px;
  padding: 0 4px;
}
#treeview-dnd.treeview-default-small .treeview-ok,
#treeview-dnd.treeview-default-small .treeview-er {
  background-image: url("backgrounds/tree_32px.png");
  background-repeat: no-repeat;
  background-color: transparent;
}
#treeview-dnd.treeview-default-small i {
  background: transparent;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
#treeview-dnd.treeview-default-small .treeview-ok {
  background-position: -7px -71px;
}
#treeview-dnd.treeview-default-small .treeview-er {
  background-position: -39px -71px;
}
.treeview-default-small .treeview-ellipsis {
  overflow: hidden;
}
.treeview-default-small .treeview-ellipsis .treeview-anchor {
  width: calc(100% - 23px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.treeview-default-small.treeview-rtl .treeview-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==");
}
.treeview-default-small.treeview-rtl .treeview-last {
  background: transparent;
}
.treeview-default-large .treeview-node {
  min-height: 32px;
  line-height: 32px;
  margin-left: 32px;
  min-width: 32px;
}
.treeview-default-large .treeview-anchor {
  line-height: 32px;
  height: 32px;
}
.treeview-default-large .treeview-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.treeview-default-large .treeview-icon:empty {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.treeview-default-large.treeview-rtl .treeview-node {
  margin-right: 32px;
}
.treeview-default-large .treeview-wholerow {
  height: 32px;
}
.treeview-default-large .treeview-node,
.treeview-default-large .treeview-icon {
  background-image: url("backgrounds/tree_32px.png");
}
.treeview-default-large .treeview-node {
  background-position: -288px 0px;
  background-repeat: repeat-y;
}
.treeview-default-large .treeview-last {
  background: transparent;
}
.treeview-default-large .treeview-open > .treeview-ocl {
  background-position: -128px 0px;
}
.treeview-default-large .treeview-closed > .treeview-ocl {
  background-position: -96px 0px;
}
.treeview-default-large .treeview-leaf > .treeview-row > .treeview-ocl {
  background-position: -64px 0px;
}
.treeview-default-large .treeview-themeicon {
  background-position: -256px 0px;
}
.treeview-default-large > .treeview-no-dots .treeview-node,
.treeview-default-large > .treeview-no-dots .treeview-leaf > .treeview-row > .treeview-ocl {
  background: transparent;
}
.treeview-default-large > .treeview-no-dots .treeview-open > .treeview-ocl {
  background-position: -32px 0px;
}
.treeview-default-large > .treeview-no-dots .treeview-closed > .treeview-ocl {
  background-position: 0px 0px;
}
.treeview-default-large .treeview-disabled {
  background: transparent;
}
.treeview-default-large .treeview-disabled.treeview-hovered {
  background: transparent;
}
.treeview-default-large .treeview-disabled.treeview-clicked {
  background: #efefef;
}
.treeview-default-large .treeview-checkbox {
  background-position: -160px 0px;
}
.treeview-default-large .treeview-checkbox:hover {
  background-position: -160px -32px;
}
.treeview-default-large.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox,
.treeview-default-large .treeview-checked > .treeview-checkbox {
  background-position: -224px 0px;
}
.treeview-default-large.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox:hover,
.treeview-default-large .treeview-checked > .treeview-checkbox:hover {
  background-position: -224px -32px;
}
.treeview-default-large .treeview-anchor > .treeview-undetermined {
  background-position: -192px 0px;
}
.treeview-default-large .treeview-anchor > .treeview-undetermined:hover {
  background-position: -192px -32px;
}
.treeview-default-large .treeview-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'treeview-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#treeview-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
.treeview-default-large > .treeview-striped {
  background-size: auto 64px;
}
.treeview-default-large.treeview-rtl .treeview-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}
.treeview-default-large.treeview-rtl .treeview-last {
  background: transparent;
}
.treeview-default-large.treeview-rtl .treeview-open > .treeview-ocl {
  background-position: -128px -32px;
}
.treeview-default-large.treeview-rtl .treeview-closed > .treeview-ocl {
  background-position: -96px -32px;
}
.treeview-default-large.treeview-rtl .treeview-leaf > .treeview-row > .treeview-ocl {
  background-position: -64px -32px;
}
.treeview-default-large.treeview-rtl > .treeview-no-dots .treeview-node,
.treeview-default-large.treeview-rtl > .treeview-no-dots .treeview-leaf > .treeview-row > .treeview-ocl {
  background: transparent;
}
.treeview-default-large.treeview-rtl > .treeview-no-dots .treeview-open > .treeview-ocl {
  background-position: -32px -32px;
}
.treeview-default-large.treeview-rtl > .treeview-no-dots .treeview-closed > .treeview-ocl {
  background-position: 0px -32px;
}
.treeview-default-large .treeview-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}
.treeview-default-large > .treeview-container-ul .treeview-loading > .treeview-ocl {
  background: url("backgrounds/tree_throbber.gif") center center no-repeat;
}
.treeview-default-large .treeview-file {
  background: url("backgrounds/tree_32px.png") -96px -64px no-repeat;
}
.treeview-default-large .treeview-folder {
  background: url("backgrounds/tree_32px.png") -256px 0px no-repeat;
}
.treeview-default-large > .treeview-container-ul > .treeview-node {
  margin-left: 0;
  margin-right: 0;
}
#treeview-dnd.treeview-default-large {
  line-height: 32px;
  padding: 0 4px;
}
#treeview-dnd.treeview-default-large .treeview-ok,
#treeview-dnd.treeview-default-large .treeview-er {
  background-image: url("backgrounds/tree_32px.png");
  background-repeat: no-repeat;
  background-color: transparent;
}
#treeview-dnd.treeview-default-large i {
  background: transparent;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
#treeview-dnd.treeview-default-large .treeview-ok {
  background-position: 0px -64px;
}
#treeview-dnd.treeview-default-large .treeview-er {
  background-position: -32px -64px;
}
.treeview-default-large .treeview-ellipsis {
  overflow: hidden;
}
.treeview-default-large .treeview-ellipsis .treeview-anchor {
  width: calc(100% - 37px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.treeview-default-large.treeview-rtl .treeview-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==");
}
.treeview-default-large.treeview-rtl .treeview-last {
  background: transparent;
}
@media (max-width: 768px) {
  #treeview-dnd.treeview-dnd-responsive {
    line-height: 40px;
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }
  #treeview-dnd.treeview-dnd-responsive > i {
    background: transparent;
    width: 40px;
    height: 40px;
  }
  #treeview-dnd.treeview-dnd-responsive > .treeview-ok {
    background-image: url("backgrounds/tree_40px.png");
    background-position: 0 -200px;
    background-size: 120px 240px;
  }
  #treeview-dnd.treeview-dnd-responsive > .treeview-er {
    background-image: url("backgrounds/tree_40px.png");
    background-position: -40px -200px;
    background-size: 120px 240px;
  }
  #treeview-marker.treeview-dnd-responsive {
    border-left-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
    margin-top: -10px;
  }
}
@media (max-width: 768px) {
  .treeview-default-responsive {
    /*
	.treeview-open > .treeview-ocl,
	.treeview-closed > .treeview-ocl { border-radius:20px; background-color:white; }
	*/
  }
  .treeview-default-responsive .treeview-icon {
    background-image: url("backgrounds/tree_40px.png");
  }
  .treeview-default-responsive .treeview-node,
  .treeview-default-responsive .treeview-leaf > .treeview-row > .treeview-ocl {
    background: transparent;
  }
  .treeview-default-responsive .treeview-node {
    min-height: 40px;
    line-height: 40px;
    margin-left: 40px;
    min-width: 40px;
    white-space: nowrap;
  }
  .treeview-default-responsive .treeview-anchor {
    line-height: 40px;
    height: 40px;
  }
  .treeview-default-responsive .treeview-icon,
  .treeview-default-responsive .treeview-icon:empty {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .treeview-default-responsive > .treeview-container-ul > .treeview-node {
    margin-left: 0;
  }
  .treeview-default-responsive.treeview-rtl .treeview-node {
    margin-left: 0;
    margin-right: 40px;
    background: transparent;
  }
  .treeview-default-responsive.treeview-rtl .treeview-container-ul > .treeview-node {
    margin-right: 0;
  }
  .treeview-default-responsive .treeview-ocl,
  .treeview-default-responsive .treeview-themeicon,
  .treeview-default-responsive .treeview-checkbox {
    background-size: 120px 240px;
  }
  .treeview-default-responsive .treeview-leaf > .treeview-row > .treeview-ocl,
  .treeview-default-responsive.treeview-rtl .treeview-leaf > .treeview-row > .treeview-ocl {
    background: transparent;
  }
  .treeview-default-responsive .treeview-open > .treeview-ocl {
    background-position: 0 0 !important;
  }
  .treeview-default-responsive .treeview-closed > .treeview-ocl {
    background-position: 0 -40px !important;
  }
  .treeview-default-responsive.treeview-rtl .treeview-closed > .treeview-ocl {
    background-position: -40px 0 !important;
  }
  .treeview-default-responsive .treeview-themeicon {
    background-position: -40px -40px;
  }
  .treeview-default-responsive .treeview-checkbox,
  .treeview-default-responsive .treeview-checkbox:hover {
    background-position: -40px -80px;
  }
  .treeview-default-responsive.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox,
  .treeview-default-responsive.treeview-checkbox-selection .treeview-clicked > .treeview-checkbox:hover,
  .treeview-default-responsive .treeview-checked > .treeview-checkbox,
  .treeview-default-responsive .treeview-checked > .treeview-checkbox:hover {
    background-position: 0 -80px;
  }
  .treeview-default-responsive .treeview-anchor > .treeview-undetermined,
  .treeview-default-responsive .treeview-anchor > .treeview-undetermined:hover {
    background-position: 0 -120px;
  }
  .treeview-default-responsive .treeview-anchor {
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }
  .treeview-default-responsive > .treeview-striped {
    background: transparent;
  }
  .treeview-default-responsive .treeview-wholerow {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(64, 64, 64, 0.2);
    background: #ebebeb;
    height: 40px;
  }
  .treeview-default-responsive .treeview-wholerow-hovered {
    background: #e7f4f9;
  }
  .treeview-default-responsive .treeview-wholerow-clicked {
    background: #beebff;
  }
  .treeview-default-responsive .treeview-children .treeview-last > .treeview-wholerow {
    box-shadow: inset 0 -6px 3px -5px #666666;
  }
  .treeview-default-responsive .treeview-children .treeview-open > .treeview-wholerow {
    box-shadow: inset 0 6px 3px -5px #666666;
    border-top: 0;
  }
  .treeview-default-responsive .treeview-children .treeview-open + .treeview-open {
    box-shadow: none;
  }
  .treeview-default-responsive .treeview-node,
  .treeview-default-responsive .treeview-icon,
  .treeview-default-responsive .treeview-node > .treeview-ocl,
  .treeview-default-responsive .treeview-themeicon,
  .treeview-default-responsive .treeview-checkbox {
    background-image: url("backgrounds/tree_40px.png");
    background-size: 120px 240px;
  }
  .treeview-default-responsive .treeview-node {
    background-position: -80px 0;
    background-repeat: repeat-y;
  }
  .treeview-default-responsive .treeview-last {
    background: transparent;
  }
  .treeview-default-responsive .treeview-leaf > .treeview-row > .treeview-ocl {
    background-position: -40px -120px;
  }
  .treeview-default-responsive .treeview-last > .treeview-ocl {
    background-position: -40px -160px;
  }
  .treeview-default-responsive .treeview-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
  }
  .treeview-default-responsive .treeview-file {
    background: url("backgrounds/tree_40px.png") 0 -160px no-repeat;
    background-size: 120px 240px;
  }
  .treeview-default-responsive .treeview-folder {
    background: url("backgrounds/tree_40px.png") -40px -40px no-repeat;
    background-size: 120px 240px;
  }

  .treeview-default-responsive > .treeview-container-ul > .treeview-node {
    margin-left: 0;
    margin-right: 0;
  }
}


//
// treeview
//

.treeview-default {
    .treeview-anchor {
        color: $dark-75;
        padding: 0 8px 0 4px;
    }

    .treeview-icon {
        color: $dark-75;

        font-size: 1.3rem;

        &.la {
            font-size: 1.5rem;
        }
        &.fa {
            font-size: 1.2rem;
        }
    }

    .treeview-disabled {
        cursor: not-allowed;
        line-height: auto;
        height: auto;
        opacity: 0.7;

        .treeview-icon {
            color: $dark-75;
        }
    }

    .treeview-clicked {
        border: 0;
        background: $gray-100;
        box-shadow: none;
    }

    .treeview-hovered {
        border: 0;
        background-color: $gray-100;
        box-shadow: none;
    }

    .treeview-wholerow-clicked,
    .treeview-wholerow-clicked {
        background: $gray-200;
        box-shadow: none;
    }

    .treeview-wholerow-hovered,
    &.treeview-wholerow .treeview-wholerow-hovered {
        border: 0;
        background-color: $gray-100;
        box-shadow: none;
    }
}

.treeview-open>.treeview-anchor>.fa-folder:before {
    margin-left: 2px;
    content: "\f07c";
}

.treeview-open>.treeview-anchor>.la-folder:before {
    margin-left: 2px;
    content: "\f200";
}

.treeview-default.treeview-rtl .treeview-node {
    background-position: 100% 1px#{'/*rtl:ignore*/'} !important;
}

.treeview-default.treeview-rtl .treeview-last {
    background: transparent #{'/*rtl:ignore*/'};
    background-repeat: no-repeat;
}

.treeview-rtl .treeview-anchor {
    padding: 0 4px 0 8px#{'/*rtl:ignore*/'};
}

// context menu
.vakata-context,
.vakata-context ul {
    padding: 0.5rem 0;
    min-width: 150px;
    font-size: 1rem;
    font-family: $font-family-base;
    background: $white;
    box-shadow: $dropdown-box-shadow;
    border: 0;
    @include border-radius($border-radius);

    li {
        padding: 0;
        border: 0;

        a {
            padding: 0rem 1.2rem;
            border: 0;
            //line-height: 2.2em;

            i {
                display: none;
            }

            .vakata-contextmenu-sep {
                display: none;
            }

            span,
            ins {
                display: none;
                border: 0 !important;
            }
        }
    }

    .vakata-context-hover>a,
    li a:hover {
        margin: 0;
        background-color: $gray-100;
        color: $primary;
        box-shadow: none;

        .span,
        .ins {
            border: 0 !important;
        }
    }
}

.vakata-context .vakata-context-separator a,
.vakata-context-rtl .vakata-context-separator a {
    margin: 0;
    border: 0;
    height: 2px;
    background-color: $gray-200;
}

.treeview-rename-input {
    outline: none !important;
    padding: 2px 6px !important;
    margin-right: -4px !important;
    background-color: $gray-100 !important;
    border: 1px solid $gray-100 !important;
    @include border-radius($border-radius);
}

