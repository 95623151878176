.pushed-layout {
  display: flex;
  flex-direction: row;
  .pusher-content {
    flex-grow: 1;
  }

  .pusher-menu {
    min-width: 300px;
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .pusher-menu {
    min-height: 90vh;
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  .pushed-layout {
    flex-direction: column;
  }
  .pusher-menu {
    min-height: 100;
  }
}
