a.focusable:focus {
  border: 1px solid $primary;
}


.help-content {
  h1, .h1 {
    font-size: 1.5rem;
  }

  h2, .h2 {
    font-size: 1.25rem;
  }

  h3, .h3 {
    font-size: 1rem;
  }

  h4, .h4 {
    font-size: 0.875rem;
  }

  font-size: 12px !important;

  ul {
    padding-left: 0px;
  }


}
