table.table-hide-empty-cells, .table.table-hide-empty-cells {
  empty-cells: hide;
}

table {
  tr.row-details {
    td {
      border-top: 0px;
      padding: 0px;
    }
  }
}

table.table-indented {
  @for $i from 1 through 10 {
    tr.indent-#{$i} {
      td.indented {
        padding-left: $i * 1rem;
      }
    }
  }
}

table.table-spaced {
  tr.spacer {
    height: 2rem;
    background-color: $white !important;
  }
}

table.table {
  td.text-muted {
    a {
      color: $gray-500;
    }
  }
}

table.table-collapsable {
  .table-row-expanded {
  }

  .tree-show-folded * {
    visibility: hidden;
  }

  .table-row-folded .tree-show-folded * {
    visibility: inherit;
  }

  .toggle-expanded {
    .expanded-icon {
      display: inline-block;
    }

    .collapsed-icon {
      display: none;
    }
  }

  .toggle-collapsed {
    .expanded-icon {
      display: none;
    }

    .collapsed-icon {
      display: inline-block;
    }
  }

  .table-parent-collapsed {
    display: block;
  }

  .table-row-collapsed {
    display: none;

  }
}

.fake-table {
  font-size: 13px;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #3f4254;
  --bs-table-striped-bg: rgba(0,0,0,0.05);
  --bs-table-active-color: #3f4254;
  --bs-table-active-bg: #e4e6ef;
  --bs-table-hover-color: #3f4254;
  --bs-table-hover-bg: #e4e6ef;
  width: 100%;
  margin-bottom: 1rem;
  color: #3f4254;
  vertical-align: top;
  border-color: #ebedf3;

  > * > * {
    border-bottom-color: currentColor;
    padding: .5rem;
    background-color: var(--bs-table-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    border-bottom-width: 0;
    border-top-width: 1px;
  }

  .th {
    border-color: inherit;
    border-style: solid;
    border-left-width: 0px;
    border-right-width: 0px;
    font-weight: bold;
    vertical-align: bottom;
  }

  .td {
    border-color: inherit;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    text-align: unset;
    vertical-align: top;
  }

  .tbody {
    border-color: inherit;
    border-style: solid;
  }

  .tr {
    border-color: inherit;
    border-style: solid;
  }

  .thead {
    border-color: inherit;
    border-style: solid;
  }
}

