.equation.stacked .equation--number {
  display: block;
  margin-left: 1em; /* space for the operator */
  text-align: right;
}

.equation.stacked .equation--label {
  text-align: left;
}

.equation.stacked .equation--operator {
  text-align: right;
}

.equation.stacked .equation--equals {
  display: block;
  height: 0;
  border-bottom: solid 1px $body-color;
  overflow: hidden;
}
