.form-control.is-valid {
  background-image: none !important;
  padding-right: inherit !important;
}

.form-control.is-invalid {
  background-image: none !important;
  padding-right: inherit !important;
}

.modal-backdrop {
  z-index: 1040;
}

/* Fixes from bootstrap 4*/
.table > :not(caption) > * > * {
  border-bottom-width: 0px;
  border-top-width: 1px;
}

.card-title {
  margin-bottom: 1.5rem;
}
/* End Fixes from bootstrap 4*/

.flex-gap {
  gap: $grid-gutter-width;
}

.opacity-0 {
  opacity:0!important;
}
.opacity-1 {
  opacity:0.2!important;
}
.opacity-2 {
  opacity:0.4!important;
}
.opacity-3 {
  opacity:0.6!important;
}
.opacity-4 {
  opacity:.8!important;
}
.opacity-5 {
  opacity:1!important;
}
