.border-left {
  border-left: 1px solid $border-color;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-right-not-last {
  border-right: 1px solid $border-color;
}

.border-right-not-last:last-child {
  border-right: 0px;
}
