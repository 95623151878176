$scrolltop-bottom-desktop: 20px;
$scrolltop-right-desktop: 20px;
$scrolltop-size-desktop:  36px;
$scrolltop-bottom-tablet: 30px;
$scrolltop-right-tablet: 15px;
$scrolltop-size-tablet: 30px;

.scrolltop-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 1px; // MS Edge requires a nominal dimension

    &.scrolltop-on {
        .scrolltop {
            opacity: 0.3;
            animation: animation-scrolltop .4s ease-out 1;
            display: flex;

            &:hover {
                transition: $transition-link;
                opacity: 1;
            }
        }
    }
}

.scrolltop {
    display: none;
    justify-content: center;
    align-items: center;
    width: $scrolltop-size-desktop;
    height: $scrolltop-size-desktop;
    position: fixed;
    bottom: $scrolltop-bottom-desktop;
    right: $scrolltop-right-desktop;
    cursor: pointer;
    z-index: 100;
    background-color: $primary;
    box-shadow: $box-shadow;
    opacity: 0;
    transition: $transition-link;
    @include border-radius($border-radius !important);

    .svg-icon {
        @include svg-icon-size(24px);
        @include svg-icon-color($white);
    }

    > i {
        font-size: 1.3rem;
        color: $white;
    }

    &:hover {
        > i {
            color: $white;
        }

        .svg-icon {
            @include svg-icon-color($white);
        }
    }

    // Tablet & Mobile Modess
    @include media-breakpoint-down(md) {
        bottom: $scrolltop-bottom-tablet;
        right: $scrolltop-right-tablet;
        width: $scrolltop-size-tablet;
        height: $scrolltop-size-tablet;
    }
}

// Animations
@keyframes animation-scrolltop {
    from   { margin-bottom: -15px }
    to {     margin-bottom: 0; }
}
