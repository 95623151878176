.card {
	box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
	border: 0;


	&.collapsable {
		&.closed {
			.card-body {
				display: none;
			}

			> .card-header .toggle-expanded {
				display: none
			}
		}

		&.show {
			> .card-header .toggle-collapsed {
				display: none
			}
		}
	}
}
