//
// NoUiSlider
//


// Base
.slider-input {
    padding-left: 50px;
    padding-right: 50px;
}
.noUi-target {
    border: 1px solid $input-border-color;
    background: $gray-100;
    box-shadow:none;
    margin-bottom: 80px;

    &.noUi-horizontal {
        height: 15px;

        .noUi-handle {
            width: 24px;
            height: 24px;
            //left: -17px;
            top: -6px;
            border-radius: 50%;
            outline: none;

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }
    }

    .noUi-value-horizontal {
        transform: translate(-50%, 70%) !important;
    }

    .noUi-value {
        padding-top: 5px;
    }

    &.noUi-vertical {
        height: 150px;
        width: 15px;

        .noUi-handle {
            width: 24px;
            height: 24px;
            top: -6px;
            border-radius: 50%;
            outline: none;

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }
    }

    .noUi-connect {
        background: $primary;
    }

    .noUi-connect:last-child {
        background: $gray-600;
    }

    .noUi-handle {
        border: 1px solid $gray-200;
        box-shadow: 0 3px 6px -3px rgba(#000, 0.7);
    }

    // State colors
    &.nouislider {
        padding-bottom: 2rem;

        @each $name, $color in $theme-colors {
            &.nouislider-connect-#{$name} {
                .noUi-connect {
                    background: $color;
                }
            }

            &.nouislider-handle-#{$name} {
                .noUi-handle {
                    border: 0;
                    background: $color;
                    box-shadow: 0 3px 6px -3px $color;
                }
            }
        }
    }
}
