.text-layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
}

.text-layer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.text-layer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: rgba(180, 0, 170, 1);
  border-radius: 4px;
}

.text-layer .highlight.begin {
  border-radius: 4px 0px 0px 4px;
}

.text-layer .highlight.end {
  border-radius: 0px 4px 4px 0px;
}

.text-layer .highlight.middle {
  border-radius: 0px;
}

.text-layer .highlight.selected {
  background-color: rgba(0, 100, 0, 1);
}

.text-layer ::-moz-selection {
  background: rgba(0, 0, 255, 1);
}

.text-layer ::selection {
  background: rgba(0, 0, 255, 1);
}

.text-layer .endOfContent {
  display: block;
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.text-layer .endOfContent.active {
  top: 0px;
}


.annotation-layer section {
  position: absolute;
}

.annotation-layer .linkAnnotation > a,
.annotation-layer .buttonWidgetAnnotation.pushButton > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotation-layer .linkAnnotation > a:hover,
.annotation-layer .buttonWidgetAnnotation.pushButton > a:hover {
  opacity: 0.2;
  background: rgba(255, 255, 0, 1);
  box-shadow: 0px 2px 10px rgba(255, 255, 0, 1);
}

.annotation-layer .textAnnotation img {
  position: absolute;
  cursor: pointer;
}

.annotation-layer .textWidgetAnnotation input,
.annotation-layer .textWidgetAnnotation textarea,
.annotation-layer .choiceWidgetAnnotation select,
.annotation-layer .buttonWidgetAnnotation.checkBox input,
.annotation-layer .buttonWidgetAnnotation.radioButton input {
  background-color: rgba(0, 54, 255, 0.13);
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 9px;
  height: 100%;
  margin: 0;
  padding: 0 3px;
  vertical-align: top;
  width: 100%;
}

.annotation-layer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotation-layer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotation-layer .textWidgetAnnotation textarea {
  font: message-box;
  font-size: 9px;
  resize: none;
}

.annotation-layer .textWidgetAnnotation input[disabled],
.annotation-layer .textWidgetAnnotation textarea[disabled],
.annotation-layer .choiceWidgetAnnotation select[disabled],
.annotation-layer .buttonWidgetAnnotation.checkBox input[disabled],
.annotation-layer .buttonWidgetAnnotation.radioButton input[disabled] {
  background: none;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.annotation-layer .textWidgetAnnotation input:hover,
.annotation-layer .textWidgetAnnotation textarea:hover,
.annotation-layer .choiceWidgetAnnotation select:hover,
.annotation-layer .buttonWidgetAnnotation.checkBox input:hover,
.annotation-layer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid rgba(0, 0, 0, 1);
}

.annotation-layer .textWidgetAnnotation input:focus,
.annotation-layer .textWidgetAnnotation textarea:focus,
.annotation-layer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 1px solid transparent;
}

.annotation-layer .buttonWidgetAnnotation.checkBox input:checked:before,
.annotation-layer .buttonWidgetAnnotation.checkBox input:checked:after,
.annotation-layer .buttonWidgetAnnotation.radioButton input:checked:before {
  background-color: rgba(0, 0, 0, 1);
  content: '';
  display: block;
  position: absolute;
}

.annotation-layer .buttonWidgetAnnotation.checkBox input:checked:before,
.annotation-layer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotation-layer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotation-layer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotation-layer .buttonWidgetAnnotation.radioButton input:checked:before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}

.annotation-layer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotation-layer .textWidgetAnnotation input.comb:focus {
  /*
   * Letter spacing is placed on the right side of each character. Hence, the
   * letter spacing of the last character may be placed outside the visible
   * area, causing horizontal scrolling. We avoid this by extending the width
   * when the element has focus and revert this when it loses focus.
   */
  width: 115%;
}

.annotation-layer .buttonWidgetAnnotation.checkBox input,
.annotation-layer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
}

.annotation-layer .popupWrapper {
  position: absolute;
  width: 20em;
}

.annotation-layer .popup {
  position: absolute;
  z-index: 200;
  max-width: 20em;
  background-color: rgba(255, 255, 153, 1);
  box-shadow: 0px 2px 5px rgba(136, 136, 136, 1);
  border-radius: 2px;
  padding: 6px;
  margin-left: 5px;
  cursor: pointer;
  font: message-box;
  font-size: 9px;
  word-wrap: break-word;
}

.annotation-layer .popup > * {
  font-size: 9px;
}

.annotation-layer .popup h1 {
  display: inline-block;
}

.annotation-layer .popup span {
  display: inline-block;
  margin-left: 5px;
}

.annotation-layer .popup p {
  border-top: 1px solid rgba(51, 51, 51, 1);
  margin-top: 2px;
  padding-top: 2px;
}

.annotation-layer .highlightAnnotation,
.annotation-layer .underlineAnnotation,
.annotation-layer .squigglyAnnotation,
.annotation-layer .strikeoutAnnotation,
.annotation-layer .freeTextAnnotation,
.annotation-layer .lineAnnotation svg line,
.annotation-layer .squareAnnotation svg rect,
.annotation-layer .circleAnnotation svg ellipse,
.annotation-layer .polylineAnnotation svg polyline,
.annotation-layer .polygonAnnotation svg polygon,
.annotation-layer .caretAnnotation,
.annotation-layer .inkAnnotation svg polyline,
.annotation-layer .stampAnnotation,
.annotation-layer .fileAttachmentAnnotation {
  cursor: pointer;
}

.pdf-viewer {
  width: 100%;
}

.pdf-viewer .canvas-wrapper {
  overflow: hidden;
  width: 100%;
}

.pdf-viewer .page {
  direction: ltr;
  margin: 1px auto -8px auto;
  position: relative;
  overflow: visible;
  width: 100%;
  // border: 9px solid transparent;
  background-clip: content-box;
  // -o-border-image: url(images/shadow.png) 9 9 repeat;
  //    border-image: url(images/shadow.png) 9 9 repeat;
  background-color: rgba(255, 255, 255, 1);
}

.pdf-viewer.removePageBorders .page {
  margin: 0px auto 10px auto;
  border: none;
}

.pdf-viewer.single-page-view {
  display: inline-block;
}

.pdf-viewer.single-page-view .page {
  margin: 0;
  border: none;
}

.pdf-viewer.scrollHorizontal, .pdf-viewer.scrollWrapped, .spread {
  margin-left: 3.5px;
  margin-right: 3.5px;
  text-align: center;
}

.pdf-viewer.scrollHorizontal, .spread {
  white-space: nowrap;
}

.pdf-viewer.removePageBorders,
.pdf-viewer.scrollHorizontal .spread,
.pdf-viewer.scrollWrapped .spread {
  margin-left: 0;
  margin-right: 0;
}

.spread .page,
.pdf-viewer.scrollHorizontal .page,
.pdf-viewer.scrollWrapped .page,
.pdf-viewer.scrollHorizontal .spread,
.pdf-viewer.scrollWrapped .spread {
  display: inline-block;
  vertical-align: middle;
}

.spread .page,
.pdf-viewer.scrollHorizontal .page,
.pdf-viewer.scrollWrapped .page {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.pdf-viewer.removePageBorders .spread .page,
.pdf-viewer.removePageBorders.scrollHorizontal .page,
.pdf-viewer.removePageBorders.scrollWrapped .page {
  margin-left: 5px;
  margin-right: 5px;
}

.pdf-viewer .page canvas {
	width: 100%;
	height: 100%;
  margin: 0;
  display: block;
}

.pdf-viewer .page canvas[hidden] {
  display: none;
}

.pdf-viewer .page .loadingIcon {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background: url('images/loading-icon.gif') center no-repeat;
}

.pdfPresentationMode .pdf-viewer {
  margin-left: 0;
  margin-right: 0;
}

.pdfPresentationMode .pdf-viewer .page,
.pdfPresentationMode .pdf-viewer .spread {
  display: block;
}

.pdfPresentationMode .pdf-viewer .page,
.pdfPresentationMode .pdf-viewer.removePageBorders .page {
  margin-left: auto;
  margin-right: auto;
}

.pdfPresentationMode:-ms-fullscreen .pdf-viewer .page {
  margin-bottom: 100% !important;
}

.pdfPresentationMode:-webkit-full-screen .pdf-viewer .page {
  margin-bottom: 100%;
  border: 0;
}

.pdfPresentationMode:-moz-full-screen .pdf-viewer .page {
  margin-bottom: 100%;
  border: 0;
}

.pdfPresentationMode:fullscreen .pdf-viewer .page {
  margin-bottom: 100%;
  border: 0;
}
