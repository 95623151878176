.header.collapsable {
  &.closed {
    .header-collapse {
      display: none;
    }

    .toggle-expanded {
      display: none
    }
  }

  &.show {
    .toggle-collapsed {
      display: none
    }
  }
}
