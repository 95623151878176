@use "sass:map";

$checkbox-config: (
	transition: all 0.3s ease,
	itemSpace: 1rem,
	labelSpace: 0.75rem,
  base-color: $gray-200,
  base: 18px,
  tick: 10px
) !default;

@mixin checkbox-solid-theme($bg-color, $checked-bg-color, $checked-tick-color) {
	// Default state
    > span {
        background-color: $bg-color;
        border: 1px solid transparent;
    }

	// Focus state
    > input:focus ~ span {
        border: 1px solid transparent;
    }

	// Checked state
    > input:checked ~ span {
        background-color: $checked-bg-color;

		&:after {
            border-color: $checked-tick-color;
        }
    }
}

@mixin checkbox-outline-theme($outline-color, $checked-outline-color, $checked-tick-color) {
	// Default state
	> span {
		background-color: transparent;
        border-color: $outline-color;
    }

	// Focus state
	> input:focus ~ span {
		background-color: transparent;
        border-color: $outline-color;
    }

	// Checked state
    > input:checked ~ span {
		background-color: transparent;
        border-color: $checked-outline-color;

		&:after {
            border-color: $checked-tick-color;
        }
    }
}

@mixin checkbox-size($base, $tick) {
	> span {
        height: $base;
        width: $base;

		&:after {
            width: $tick / 2;
            height: $tick;
		}
	}
}
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  transition: map.get($checkbox-config, transition);
  margin: 0;

  > span {
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius($border-radius);

    &:after {
      content:'';
      border-color: transparent;
      border-style: solid;
      border-width: 0 2px 2px 0 !important;
      transform: rotate(45deg);
      margin-top: -2px;
    }
  }

  // Hide default browser input
  > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  // Hover & focus states
  &:hover > input:not([disabled]) ~ span,
  > input:focus ~ span {
    transition: map.get($checkbox-config, transition);
  }

  // Disabled state
  &.checkbox-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  // Checked state
  > input:checked ~ span {
    transition: map.get($checkbox-config, transition);
    background-color: none;

    &:after {
      display: block;
    }
  }
}

// Theme colors
.checkbox {
  // Default style
  @include checkbox-solid-theme(map.get($checkbox-config, base-color), $primary, $white);
}

// Inline checkbox
.checkbox-inline {
  display: flex;

  .checkbox {
    margin-right: map.get($checkbox-config, itemSpace);

    span {
      margin-right: map.get($checkbox-config, labelSpace);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

// Input group
.input-group {
  .checkbox {
    margin-bottom: 0 !important;
    padding-left: 0;
  }
}

// Sizes
.checkbox {
    // Solid type
    @include checkbox-size(map.get($checkbox-config,base), map.get($checkbox-config, tick));
}


// Form integration
.form-inline {
  .checkbox {
    margin-left: 15px;
    margin-right: 15px;
  }
}
