@use 'sass:map';
//
// Aside
//
$aside-config: (
	// Aside
	base: (
		width: 325px,  // AsideAside's primary panel's width for desktop mode(see "aside-secondary" class)
		width-tablet-and-mobile: 225px, // Aside's primary panel's width for mobile mode(see "aside-secondary" class)
		transition: all 0.3s ease, // General transition
		toggle-space: $page-padding-desktop, // Aside's toggle button offset(see "aside-toggle" class)
		bg-color: $white,  // Aside's background color
		shadow: none, //5px 0px 10px rgba(70, 78, 95, 0.05), // Aside's box shadow
		zindex: 98, // Aside's z-index property
		scroll-width: 4px // Aside's custom scrollbar's width
	),
) !default;

// Desktop Mode
@include media-breakpoint-up(lg) {
	// pusher
	.aside-pusher {
		transition: map.get($aside-config, base, transition);

	}

}

// Tablet & Mobile Modes
// @include media-breakpoint-down(md) {
// 	// aside-pusher
// 	.aside-pusher {
// 		// Fixed Header
// 		.header-mobile-fixed & {
// 			padding-top: get($header-config, tablet-and-mobile, fixed, height);
// 		}
// 	}
// }

// General Mode(all devices)
.aside {
	.aside-panel {
		box-shadow: map.get($aside-config, base, shadow);
		transition: map.get($aside-config, base, transition);

		background-color: map.get($aside-config, base, bg-color);
		width: map.get($aside-config, base, width);
	}

}

// Desktop Mode
@include media-breakpoint-up(lg) {
	.aside {
		// Fixed Aside Mode
		//
		.aside-panel {
			min-height: 90vh;
		}

		// Static Aside Mode
		.aside-panel.aside-static {
			position: relative;
			z-index: 1;
		}
	}
}

.aside-pusher {
  @each $breakpoint, $container-max-width in $container-max-widths {
		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
			.container {
				max-width: 0.8*$container-max-width;
			}
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
}
