
//
// menu
//

// Variables
$menu-link-px: 1.5rem;
$menu-link-py: 0.75rem;

$menu-header-px: 1.5rem;
$menu-header-py: 0.75rem;

$menu-footer-px: 1.5rem;
$menu-footer-py: 0.75rem;

$menu-section-px: 1.5rem;
$menu-section-py: 0.75rem;

$menu-link-elem-space: 0.75rem;

$menu-link-opacity-disabled: 0.6;

$menu-separator: $border-color;
$menu-border: $border-color;

$menu-link-bg-hover: $gray-100;
$menu-link-bg-active: $gray-100;

$menu-link-bullet-bg: $gray-300;
$menu-link-bullet-bg-hover: $primary;
$menu-link-bullet-bg-active: $primary;

$menu-link-text-font-size: 1rem;
$menu-link-text-font-size-lg: 1.1rem;
$menu-link-text-font-size-xl: 1.2rem;

$menu-link-text-color: $dark-75;
$menu-link-text-color-hover: $primary;
$menu-link-text-color-active: $primary;

$menu-link-icon-circle-width: 4rem;
$menu-link-icon-circle-width-lg: 4.5rem;
$menu-link-icon-circle-width-xl: 5rem;
$menu-link-icon-width: 2rem;
$menu-link-icon-width-lg: 3rem;
$menu-link-icon-width-xl: 4rem;

$menu-link-icon-font-size: 1.25rem;
$menu-link-icon-font-size-lg: 1.5rem;
$menu-link-icon-font-size-xl: 1.75rem;

$menu-link-icon-svg-size: 22px;
$menu-link-icon-svg-size-lg: 28px;
$menu-link-icon-svg-size-xl: 32px;

$menu-link-icon-color: $gray-500;
$menu-link-icon-light-color: $gray-400;
$menu-link-icon-color-hover: $primary;
$menu-link-icon-color-active: $primary;

$menu-link-icon-circle-bg: $gray-100;
$menu-link-icon-circle-bg-hover: $gray-200;
$menu-link-icon-circle-bg-active: $gray-200;

$menu-link-arrow-font-size: 0.6rem;
$menu-link-arrow-color: $gray-500;
$menu-link-arrow-color-hover: $primary;
$menu-link-arrow-color-active: $primary;

@mixin menu-link-theme($bullet-bg, $text-color, $icon-color, $arrow-color, $link-bg, $link-border-bottom) {
    @if ($bullet-bg != null) {
        .menu-bullet {
            .bullet {
                background-color: $bullet-bg;
            }
        }
    }

    @if ($text-color != null) {
        color: $text-color;

        .menu-text {
            color: $text-color;
        }
    }

    @if ($icon-color != null) {
        .menu-icon {
            // Font icon
            i {
                color: $icon-color;
            }

            // Svg icon
            @include svg-icon-color($icon-color);
        }
    }

    @if ($arrow-color != null) {
        .menu-arrow {
            color: $arrow-color;
        }
    }

    @if ($link-bg != null) {
        background-color: $link-bg;
    }

    @if ($link-border-bottom != null) {
        border-bottom: 1px solid $link-border-bottom;
    }
}

@mixin menu-icon-size($width, $font-size, $svg-size) {
    .menu-icon {
        flex: 0 0 $width;

        i {
            font-size: $font-size;
        }

        @include svg-icon-size($svg-size);
    }
}

@mixin menu-font-size($font-size) {
    font-size: $font-size;

    .menu-text {
        font-size: $font-size;
    }
}

@mixin menu-link-transition($transition) {
    transition: $transition;

    .menu-bullet {
        .bullet {
            transition: $transition;
        }
    }

    .menu-text {
        transition: $transition;
    }

    .menu-icon {
        transition: $transition;

        i {
            transition: $transition;
        }

        // Svg icon
        .svg-icon {
            @include svg-icon-transition();
        }
    }

    .menu-arrow {
        transition: $transition;
    }
}

// Base
.menu {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;

    .menu-item {
        padding: 0;
        display: block;
        list-style: none;

        .menu-link {
            display: flex;
            align-items: center;
            padding: $menu-link-py $menu-link-px;

            .menu-icon {
                line-height: 0;
                flex-shrink: 0;

                &:before {
                    line-height: 0;
                }
            }

            .menu-bullet {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                margin-right: $menu-link-elem-space;
            }

            .menu-text {
                flex-grow: 1;
            }

            .menu-badge {
                line-height: 0;
                margin-left: $menu-link-elem-space;
            }

            .menu-toolbar {
                margin-left: $menu-link-elem-space;
            }

            .menu-arrow {
                line-height: 0;
                margin-left: $menu-link-elem-space;
                transition: $transition;
                color: $dark-50;

                &:before {
                    line-height: 0;
                    font-size: $menu-link-arrow-font-size;
                }

                &.menu-arrow-down {
                    transform: rotateZ(-90deg)#{'/*rtl:ignore*/'};

                    [direction="rtl"] & {
                        transform: rotateZ(90deg)#{'/*rtl:ignore*/'};
                    }
                }
            }
        }

        &:last-child {
            border-bottom: 0 !important;

            .menu-link {
                border-bottom: 0 !important;
            }
        }
    }

    .menu-separator {
        margin: 0;
        padding: 0;
        height: 0;
        display: block;
        border-bottom: 1px solid $border-color;

        &.menu-separator-dashed {
            border-bottom: 1px dashed $border-color;
        }
    }

    .menu-header,
    .menu-footer,
    .menu-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu-section {
        padding: $menu-section-py $menu-section-px;
    }

    .menu-header {
        padding: $menu-header-py $menu-header-px;
    }

    .menu-footer {
        padding: $menu-footer-py $menu-footer-px;
    }
}

// Without horizontal spacer
.menu.menu-spacer-x-0 {
    .menu-item {
        .menu-link {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Icon Sizes
.menu {
    .menu-item {
        .menu-link {
            @include menu-icon-size($menu-link-icon-width,  $menu-link-icon-font-size,  $menu-link-icon-svg-size);
        }
    }

    &.menu-icon-lg {
        .menu-item {
            .menu-link {
                @include menu-icon-size($menu-link-icon-width-lg,  $menu-link-icon-font-size-lg,  $menu-link-icon-svg-size-lg);
            }
        }
    }

    &.menu-icon-xl {
        .menu-item {
            .menu-link {
                @include menu-icon-size($menu-link-icon-width-xl,  $menu-link-icon-font-size-xl,  $menu-link-icon-svg-size-xl);
            }
        }
    }

    &.menu-icon-center {
        .menu-item {
            .menu-link {
                .menu-icon {
                    text-align: center;
                }
            }
        }
    }
}

// Font Sizes
.menu {
    .menu-item {
        .menu-link {
            @include menu-font-size($menu-link-text-font-size);
        }
    }

    &.menu-font-lg {
        .menu-item {
            .menu-link {
                @include menu-font-size($menu-link-text-font-size-lg);
            }
        }
    }

    &.menu-font-xl {
        .menu-item {
            .menu-link {
                @include menu-font-size($menu-link-text-font-size-xl);
            }
        }
    }
}

// Font Weight Options
.menu {
    &.menu-light {
        .menu-item {
            .menu-link {
                .menu-text {
                    font-weight: $font-weight-light;
                }
            }
        }
    }

    &.menu-lighter {
        .menu-item {
            .menu-link {
                .menu-text {
                    font-weight: $font-weight-lighter;
                }
            }
        }
    }

    &.menu-bold {
        .menu-item {
            .menu-link {
                .menu-text {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &.menu-bolder {
        .menu-item {
            .menu-link {
                .menu-text {
                    font-weight: $font-weight-bolder;
                }
            }
        }
    }

    &.menu-boldest {
        .menu-item {
            .menu-link {
                .menu-text {
                    font-weight: $font-weight-boldest;
                }
            }
        }
    }
}

// Default Theme
.menu {
    .menu-item {
        .menu-link {
            // Default transitions
            @include menu-link-transition($transition);

            // Default state
            @include menu-link-theme($menu-link-bullet-bg, $menu-link-text-color, $menu-link-icon-color, $menu-link-arrow-color, null, null);

            // Disabled state
            &.disabled {
                opacity: $menu-link-opacity-disabled;
            }

            &:hover {
                @include menu-link-transition($transition);
                @include menu-link-theme($menu-link-bullet-bg-hover, $menu-link-text-color-hover, $menu-link-icon-color-hover, $menu-link-arrow-color-hover, null, null);
            }

            &.active {
                @include menu-link-transition($transition);
                @include menu-link-theme($menu-link-bullet-bg-active, $menu-link-text-color-active, $menu-link-icon-color-active, $menu-link-arrow-color-active, null, null);
            }
        }
    }
}

// Light Icon Theme
.menu.menu-light-icon {
    .menu-item {
        .menu-link {
            // Default transitions
            @include menu-link-transition($transition);

            // Default state
            @include menu-link-theme($menu-link-bullet-bg, $menu-link-text-color, $menu-link-icon-light-color, $menu-link-arrow-color, null, null);

            // Disabled state
            &.disabled {
                opacity: $menu-link-opacity-disabled;
            }

            &:hover {
                @include menu-link-transition($transition);
                @include menu-link-theme($menu-link-bullet-bg-hover, $menu-link-text-color-hover, $menu-link-icon-color-hover, $menu-link-arrow-color-hover, null, null);
            }

            &.active {
                @include menu-link-transition($transition);
                @include menu-link-theme($menu-link-bullet-bg-active, $menu-link-text-color-active, $menu-link-icon-color-active, $menu-link-arrow-color-active, null, null);
            }
        }
    }
}

// Border Style
.menu {
    &.menu-border {
        .menu-item {
            .menu-link {
                border-bottom: 1px solid $border-color;
            }
        }
    }
}

// Border Radius
.menu {
    &.menu-link-rounded {
        .menu-item {
            .menu-link {
                border-radius: $border-radius;
            }
        }
    }

    &.menu-link-rounded-lg {
        .menu-item {
            .menu-link {
                border-radius: $border-radius-lg;
            }
        }
    }
}

// Background Color Style
.menu {
    &.menu-hover {
        .menu-item {
            .menu-link:hover {
                background-color: $menu-link-bg-hover;
            }
        }
    }

    &.menu-active {
        .menu-item {
            .menu-link.active {
                background-color: $menu-link-bg-active;
            }
        }
    }
}

// Accent Style
.menu {
    &.menu-accent {
        .menu-item {
            .menu-link {
                position: relative;

                &:before {
                    display: block;
                    left: 0;
                    top: 8px;
                    bottom: 8px;
                    position: absolute;
                    width: 4px;
                    background-color: transparent;
                    transition: $transition;
                    @include border-radius(2px);
                    content: " ";
                }

                &.active {
                    &:before {
                        background-color: $primary;
                        transition: $transition;
                    }
                }
            }
        }

        // Theme colors
        @each $name, $color in $theme-colors {
            &.menu-#{$name} {
                .menu-item {
                    .menu-link {
                        &:hover {
                            @include menu-link-theme(null, $color, $color, $color, null, null);
                        }

                        &.active {
                            @include menu-link-theme(null, $color, $color, $color, null, null);

                            &:before {
                                background-color: $color;
                            }
                        }
                    }
                }
            }
        }
    }
}
